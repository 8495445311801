const timeZones = [
  { abbreviation: "ET", name: "Eastern" },
  { abbreviation: "CT", name: "Central" },
  { abbreviation: "MT", name: "Mountain" },
  { abbreviation: "PT", name: "Pacific" },
  { abbreviation: "AKT", name: "Alaska" },
  { abbreviation: "HAST", name: "Hawaii-Aleutian" },
];

const timeSlots = {
  ET: [
    { start: "11:00 AM", end: "1:00 PM" },
    { start: "1:00 PM", end: "3:00 PM" },
    { start: "3:00 PM", end: "5:00 PM" },
    { start: "5:00 PM", end: "7:00 PM" },
    { start: "7:00 PM", end: "8:00 PM" },
  ],
  CT: [
    { start: "10:00 AM", end: "12:00 PM" },
    { start: "12:00 PM", end: "2:00 PM" },
    { start: "2:00 PM", end: "4:00 PM" },
    { start: "4:00 PM", end: "6:00 PM" },
    { start: "6:00 PM", end: "7:00 PM" },
  ],
  MT: [
    { start: "9:00 AM", end: "11:00 AM" },
    { start: "11:00 AM", end: "1:00 PM" },
    { start: "1:00 PM", end: "3:00 PM" },
    { start: "3:00 PM", end: "5:00 PM" },
    { start: "5:00 PM", end: "6:00 PM" },
  ],
  PT: [
    { start: "8:00 AM", end: "10:00 AM" },
    { start: "10:00 AM", end: "12:00 PM" },
    { start: "12:00 PM", end: "2:00 PM" },
    { start: "2:00 PM", end: "4:00 PM" },
    { start: "4:00 PM", end: "5:00 PM" },
  ],
  AKT: [
    { start: "7:00 AM", end: "9:00 AM" },
    { start: "9:00 AM", end: "11:00 AM" },
    { start: "11:00 AM", end: "1:00 PM" },
    { start: "1:00 PM", end: "3:00 PM" },
    { start: "3:00 PM", end: "4:00 PM" },
  ],
  HAST: [
    { start: "6:00 AM", end: "8:00 AM" },
    { start: "8:00 AM", end: "10:00 AM" },
    { start: "10:00 AM", end: "12:00 PM" },
    { start: "12:00 PM", end: "2:00 PM" },
    { start: "2:00 PM", end: "3:00 PM" },
  ],
};

const options = [
  {
    value: "Web Design And Development",
    label: "Web Design And Development",
  },
  { value: "Mobile App Development", label: "Mobile App Development" },
  { value: "Print, Logo & Branding", label: "Print, Logo & Branding" },
  // {
  //   value: "Social Media & Presentation Design",
  //   label: "Social Media & Presentation Design",
  // },
  // { value: "Video Editing & Animation", label: "Video Editing & Animation" },
  // {
  //   value: "Brand Brand Development & Strategy",
  //   label: "Brand Brand Development & Strategy",
  // },
  // {
  //   value: "Content Content management & Marketing",
  //   label: "Content Content management & Marketing",
  // },
  { value: "SEO & Paid Advertising", label: "SEO & Paid Advertising" },
  { value: "Free Consultancy", label: "Free Consultancy" },
];

const BudgetValues = [
  {
    value: "$500 - $1,000",
    label: "$500 - $1,000",
  },
  { value: "$1,000 - $5,000", label: "$1,000 - $5,000" },
  { value: "$5,000 - $10,000", label: "$5,000 - $10,000" },
  {
    value: "$10,000 - $20,000",
    label: "$10,000 - $20,000",
  },
  { value: "$20,000+", label: "$20,000+" },
  { value: "Free consultancy", label: "Free consultancy" },
];

const BeginProject = [
  {
    value: "As soon as possible",
    label: "As soon as possible",
  },
  { value: "In the next week", label: "In the next week" },
  { value: "In the next 2 weeks", label: "In the next 2 weeks" },
  {
    value: "In the next month",
    label: "In the next month",
  },
  { value: "In the next few months", label: "In the next few months" },
  {
    value: "As recommended by the professional",
    label: "As recommended by the professional",
  },
];

const hiringDecision = [
  {
    value: "I'm ready to hire now",
    label: "I'm ready to hire now",
  },
  {
    value: "I'm definitely going to hire someone",
    label: "I'm definitely going to hire someone",
  },
  { value: "I'm likely to hire someone", label: "I'm likely to hire someone" },
  {
    value: "I will possibly hire someone",
    label: "I will possibly hire someone",
  },
  {
    value: "I'm planning and researching",
    label: "I'm planning and researching",
  },
];

const initialValues = {
  name: "",
  email: "",
  phone: "",
  business: "",
  timeToCall: "",
  selectedOptionBudget: "",
  selectedOption: null,
  selectProjectBegin: null,
  selectHiringDecision: null,
  message: "",
  isSubmitting: false,
};

const textSliderData = [
  { text: `RESULT <br /> ORIENTED WEB <br /> DESIGN` },
  { text: `STRATEGIC <br /> DESIGN , LASTING <br /> IMPACT` },
  { text: `TRANSFORMING <br /> VISIONS INTO <br /> APPS` },
];

export {
  timeZones,
  timeSlots,
  options,
  BudgetValues,
  initialValues,
  textSliderData,
  BeginProject,
  hiringDecision,
};
