import React from "react";
import { GilroyFontDark, GilroyFontLight } from "@lib/GilroyFont";
import Image from "next/image";

const AfterFormSubmit = () => {
  return (
    <div className="after-form-submit w-[100%]">
      <style jsx>
        {`
          .after-form-submit {
            width: calc(10vw + 20rem);
            height: calc(10vh + 20rem);
            background: white;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            flex-direction: column;
            padding: 15px;
            border-radius: 20px;
            transition: ease-in;
            duration: 400;
          }
        `}
      </style>
      <Image
                    height={200}
                    width={200}
                    className="pt-10"
                    alt="facebook icon"
                    src="/success-purple-thanks.webp"
                  />

      <p
        className={` ${GilroyFontDark.className} text-black text-[40px] text-center `}
      >
        Thank You!{" "}
      </p>
      <p className={`${GilroyFontLight.className} text-[24px] text-center`}>
        for submitting the form, we will be contacting you shortly!{" "}
      </p>
    </div>
  );
};

export default AfterFormSubmit;
