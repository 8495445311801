import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInputCustom = ({ formik }) => {
  //const placeHolderLength = 3;
  //const placeHolderCalculate = placeHolderLength * 10 + "px";

  return (
    <div className="mb-2 w-[100%]">
      <span
        style={{
          "--placeholder-text": "-10.5px",
        }}
        className={` input-group `}
      >
        <PhoneInput
          className={` w-[15rem] border-b-[5px]  ${formik.touched["phone"] && formik.errors["phone"] ? "ease-in duration-300 border-red-500" : "border-gray-300"}  sm:w-[20rem] text-[13px]  text-gray-400 outline-none `}
          country={"us"} // Default country (you can change this as needed)
          value={formik.values.phone}
          onChange={(value, country) => {
            formik.setFieldValue("phone", value);
          }}
          inputStyle={{
            width: "100%",
            // borderBottom: '5px solid #ccc',
            color: "black",
            outline: "none",
            fontSize: "13px",
          }}
          containerStyle={{ width: "100%" }}
          inputProps={{
            name: "phone",
            required: true,
            placeholder: "Phone",
            outline: "none",
            fontSize: "13px",
          }}
          onBlur={formik.handleBlur}
        />
      </span>

      {/* {formik.touched.phone && formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null} */}
    </div>
  );
};

export default PhoneInputCustom;
