const InputComponent = ({
  className,
  id,
  value,
  placeholder,
  formik,
  required,
}) => {
 // const placeHolderLength = placeholder.length ? placeholder.length : 1;
  //const placeHolderCalculate = placeHolderLength * 10 + "px";

  return (
    <div className="mb-2 w-[100%] relative">
      <span
        style={{
          "--placeholder-text": "-10px",
          fontSize: "100%",
        }}
        className={` ${required === true ? "input-group" : ""}   `}
      >
        <label>
        <input
          type="text"
          name={value}
          id={id}
          className={`text-[13px]  py-2 input-placeholder ${className} ${formik.touched[value] && formik.errors[value] ? "ease-in duration-300 border-red-500" : ""}  `}
          value={formik.values[value]}
          onChange={formik.handleChange}
          required={required}
          placeholder={placeholder}
          onBlur={formik.handleBlur}
          aria-label={value}
          style={{
            "::placeholder": { color: "red" }, // This might not work in all browsers
          }}
        />
        </label>
      </span>

      {/* use this to show form Error */}
      {/* {formik.touched[value] && formik.errors[value] ? (
      <div className="error">{formik.errors[value]}</div>
    ) : null} */}
    </div>
  );
};

export default InputComponent;
